import React from "react";
import "./App.css";

import {HeroHeader} from "./components/HeroHeader";
import {About} from "./components/About";
import {AreaOfActivity} from "./components/AreaOfActivity";
import {HowItWorks} from "./components/HowItWorks";
import {WhoWeAre} from "./components/WhoWeAre";
import {ContactUs} from "./components/ContactUs";
import {Footer} from "./components/Footer";

const App = () => {
    return (
        <div className="main">
            <HeroHeader/>
            <About/>
            <HowItWorks/>
            <AreaOfActivity/>
            <WhoWeAre/>
            <ContactUs/>
            <Footer/>
        </div>
    );
};
export default App;
