import smbolo from "../../assets/smbolo.svg";
import world from "../../assets/world.png";
import eleitoral from "../../assets/eleitoral.png";
import tributario from "../../assets/tributario.png";
import trabalhista from "../../assets/trabalhista.png";
import integridade from "../../assets/integridade.png";
import law from "../../assets/law.png";

import "./styles.css";

export const AreaOfActivity = () => {
    const cardsData = [
        {
            text: "DIREITO PÚBLICO",
            image: world,
        },
        {
            text: "DIREITO ELEITORAL",
            image: eleitoral,
        },
        {
            text: "DIREITO TRIBUTÁRIO",
            image: tributario,
        },
    ];
    const cardsDataLineTwo = [
        {
            text: "DIREITO TRABALHISTA",
            image: trabalhista,
        },
        {
            text: "PROGRAMA DE INTEGRIDADE",
            image: integridade,
        },
        {
            text: "LICITAÇÕES E CONTRATOS",
            image: law,
        },
    ];
    return (
        <div className="area-of-activity">
            <div className="area-of-activity__logo container">
                <img src={smbolo} className="area-of-activity__logo"/>
            </div>
            <div className="area-of-activity__content container">
                <h2 className="brown fw-bold mb-5">Nossas áreas de atuação</h2>
                <div className="area-of-activity__content__cards mb-5">
                    {cardsData.map((card) => (
                        <div className="cards">
                            <img src={card.image} alt={""} />
                            <span className="brown-red mt-5 fs-5">{card.text}</span>
                        </div>
                    ))}
                </div>
                <div className="area-of-activity__content__cards mb-5">
                    {cardsDataLineTwo.map((card) => (
                        <div className="cards">
                            <img src={card.image} alt={""} />
                            <span className="brown-red mt-5 fs-5">{card.text}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
