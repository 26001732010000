import localImg from "../../assets/local.png";
import mailImg from "../../assets/mail.png";
import phoneImg from "../../assets/phone.png";

import "./styles.css";

import {useEffect, useState} from "react";

export const ContactUs = () => {
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [mail, setMail] = useState();
    const [state, setState] = useState();
    const [citie, setCitie] = useState();
    const [linkWhatsapp, setLinkWhatsapp] = useState("https://wa.me/555131110010?text=");

    const handleSelectChangeName = async (event) => {
        await setName(event.target.value);
        functionSetLinkWhatsapp();
    };

    const handleSelectChangePhone = async (event) => {
        await setPhone(event.target.value);
        functionSetLinkWhatsapp();
    };

    const handleSelectChangeMail = async (event) => {
        await setMail(event.target.value);
        functionSetLinkWhatsapp();
    };

    const handleSelectChangeState = async (event) => {
        await setState(event.target.value);
        functionSetLinkWhatsapp();
    };

    const handleSelectChangeCitie = async (event) => {
        await setCitie(event.target.value);
        functionSetLinkWhatsapp();
    };

    const functionSetLinkWhatsapp = () => {
        setLinkWhatsapp(
            "https://wa.me/555131110010?text="
            + "Meu%20nome%20é%20*" + (name ?? "?")
            + "*%20e%20quero%20ser%20um%20cliente."
            + "%20Telefone:%20" + (phone ?? "?")
            + "%20E-mail:%20" + (mail ?? "?")
            + "%20UF:%20" + (state ?? "?")
            + "%20Cidade:%20" + (citie ?? "?")
        );
    };

    useEffect(() => {
        fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
            .then((response) => response.json())
            .then((data) => {
                data.sort((a, b) => a.nome.localeCompare(b.nome));

                setStates(data);
            });
    }, []);

    useEffect(() => {
        fetch(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
        )
            .then((response) => response.json())
            .then((data) => setCities(data));
    }, [state]);

    return (
        <div
            className="contact-us d-flex align-items-center justify-content-center flex-column"
            id="formulario"
        >
            <div className="container">
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <h2 className="brown-dark fw-bold mb-5 text-white">Fale conosco</h2>
                    <p className="brown-dark text-white">
                        Fale agora com nossos especialistas para ser um cliente{" "}
                        <strong className="brown-dark text-white"> MHADV</strong> {" "}
                        e se preocupe só com o que você faz de melhor, que nós faremos o mesmo!
                    </p>
                </div>
                <div className="form-container my-5">
                    <div className="inputs-container">
                        <input type="text" value={name} onChange={handleSelectChangeName}
                               placeholder="Nome completo:* "/>
                        <div className="input-group">
                            <input
                                type="tel"
                                value={phone}
                                onChange={handleSelectChangePhone}
                                placeholder="Telefone:* "
                                onkeyup={(event) => handlePhone(event)}
                            />
                            <input type="mail" value={mail} onChange={handleSelectChangeMail} placeholder="E-mail:* "/>
                        </div>
                        <div className="input-group">
                            <select onChange={handleSelectChangeState}>
                                <option hidden>Estado:</option>
                                <option>
                                    Selecione
                                </option>

                                {states.map((state) => (
                                    <option
                                        value={state.sigla}
                                        key={state.id}
                                    >
                                        {state.nome}
                                    </option>
                                ))}
                            </select>
                            <select disabled={cities.length === 0} onChange={handleSelectChangeCitie}>
                                <option hidden>Cidade:</option>
                                {cities.map((city) => (
                                    <option value={city.nome} key={city.id}>
                                        {city.nome}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start align-items-center w-100 checkbox-container">
                        <input
                            type="checkbox"
                            placeholder=""
                            className="me-3"
                            name="politica"
                            id="politica"
                        />
                        <label className="brown-dark ls-6 text-white" htmlFor="politica">
                            Li e concordo com a Política de Privacidade desta página
                        </label>
                    </div>
                    <div className="hero-header__content__button mt-5">
                        <a href={linkWhatsapp} target="_blank">
                            <button className="btn-hero-footer">Quero ser um cliente</button>
                        </a>
                    </div>
                </div>
                <div className="mt-5 d-flex justify-content-center align-items-center text-center contatos">
                    <label className="brown-dark ls-6 text-white me-3">
                        <img src={localImg} className="me-3"/>
                        <a target="_blank" href="https://maps.app.goo.gl/zE691xe31wTm8DMj9">
                            Av. Ipiranga 40, sala 709, Praia de Belas - Porto Alegre
                        </a>
                    </label>
                    <label className="brown-dark ls-6 text-white me-3">
                        <img src={mailImg} className="me-3"/>
                        <a href="mailto:contato@mhadv.com.br">contato@mhadv.com.br</a>
                    </label>
                    <label className="brown-dark ls-6 text-white me-3">
                        <img src={phoneImg} className="me-3"/>
                        <a href="tel:+555131110010">51 3111-0010</a>
                    </label>
                </div>
            </div>
        </div>
    );
};
