import smbolo from "../../assets/smbolo.svg";
import "./styles.css";

export const Footer = () => {
    return (
        <footer>
            <div className="container d-flex justify-content-between align-items-center py-1">
                <small className="fw-light brown">
                    Todos os direitos reservados | © Copyright MHADV
                </small>
                <div className="footer__logo">
                    <img src={smbolo} className="footer__logo"/>
                </div>
            </div>
        </footer>
    );
};
