import whatsapp from "../../assets/whats.png";

import "./styles.css";

export const HeroHeader = () => {
    return (
        <div className="hero-header">
            <a href="https://wa.me/555131110010" className="whatsapp" target="_blank">
                <img src={whatsapp} alt="whats" className="whatsapp-icon"/>
            </a>
            <div className="imagem-fundo">
                <div className="container">
                    <div className="d-flex align-items-center hero-container-content">
                        <div className="hero-header__content">
                            <div></div>
                            <div className="hero-header__content__title">
                                <h2>
                                    Individualidade, <strong className="brown">confiança,</strong> comprometimento
                                    e{" "}
                                    <strong className="gray">seriedade</strong>
                                </h2>
                                <p>
                                    Se preocupe só com o que você faz
                                    <br/> de melhor, que nós faremos o mesmo!
                                </p>
                            </div>
                            <div className="btn-hero-header">
                                <a className="btn-hero-header-a" href="#formulario">
                                    Quero ser um cliente
                                </a>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
