import Lucas from "../../assets/lucas_madsen.png";
import Virgine from "../../assets/virginie_lopes.png";
import Carolyne from "../../assets/carolyne_espitalher.png";

import "./styles.css";

import {useState} from "react";

export const WhoWeAre = () => {
    const [isHoveredLucas, setHoveredLucas] = useState(false);
    const [isHoveredVirgine, setHoveredVirgine] = useState(false);
    const [isHoveredCarolyne, setHoveredCarolyne] = useState(false);
    return (
        <div className="who-we-are container">
            <div className="who-we-are__text">
                <h2 className="fw-bold brown-light mb-3">Quem somos</h2>
            </div>
            <div className="mt-5 d-flex flex-column flex-md-row align-items-center justify-content-center">
                <div className="d-flex align-items-center flex-column me-0 me-3"
                     onMouseEnter={() => setHoveredLucas(true)}
                     onMouseLeave={() => setHoveredLucas(false)}
                >
                    <div className="p-3 img-background">
                        <img src={Lucas}
                             className="foto-perfil"
                        />
                        {isHoveredLucas && (
                            <div className="balao">
                                <span className="brown fw-bold mt-3">
                                    Advogado, Mestrando em Direito Público pela Universidade La Salle,
                                    com 10 anos de atuação específica em Direito Administrativo, Público e Eleitoral.
                                </span>
                            </div>
                        )}
                    </div>
                    <span className="brown fw-bold mt-3">Lucas Madsen Hanisch</span>
                    <span className="brown fw-bold mt-0">OAB/RS089.752</span>
                </div>
                <div className="d-flex align-items-center flex-column me-0 me-3"
                     onMouseEnter={() => setHoveredVirgine(true)}
                     onMouseLeave={() => setHoveredVirgine(false)}
                >
                    <div className="p-3 img-background">
                        <img src={Virgine}
                             className="foto-perfil"
                        />
                        {isHoveredVirgine && (
                            <div className="balao">
                                <span className="brown fw-bold mt-3">
                                    Advogada, Bacharel em Direito, pela Universidade Federal de Rio Grande.
                                    Especialista em Direito Tributário e Aduaneiro, pela PUC/Minas.
                                </span>
                            </div>
                        )}
                    </div>
                    <span className="brown fw-bold mt-3">Virginie Lopes Hanisch</span>
                    <span className="brown fw-bold mt-0">OAB/RS120.479</span>
                </div>
                <div className="d-flex align-items-center flex-column me-0 me-3"
                     onMouseEnter={() => setHoveredCarolyne(true)}
                     onMouseLeave={() => setHoveredCarolyne(false)}
                >
                    <div className="p-3 img-background">
                        <img src={Carolyne}
                             className="foto-perfil"
                        />
                        {isHoveredCarolyne && (
                            <div className="balao">
                                <span className="brown fw-bold mt-3">
                                    Bacharel em Direito pelo Centro Universitário Cesuca.
                                    Pós-Graduanda em Direito Administrativo pelo Centro Universitário Ritter dos Reis.
                                </span>
                            </div>
                        )}
                    </div>
                    <span className="brown fw-bold mt-3">Carolyne Espitalher</span>
                    <span className="brown fw-bold mt-0">Bacharel em Direito</span>
                </div>
            </div>
        </div>
    );
};
