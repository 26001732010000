import text_edit from "../../assets/text_edit.png";
import box_question from "../../assets/box_question.png";
import box_check from "../../assets/box_check.png";
import smbolo from "../../assets/smbolo.svg";

import "./styles.css";

export const HowItWorks = () => {
  const cardsData = [
    {
      text: "Agindo de forma preventiva",
      image: text_edit,
    },
    {
      text: "Prestando consultoria e orientações nas demandas necessárias",
      image: box_question,
    },
    {
      text: "Alcançando a solução ideal",
      image: box_check,
    },
  ];
  return (
    <div className="how-it-works">
      <div className="how-it-works__logo container">
        <img src={smbolo} className="how-it-works__logo"/>
      </div>
      <div className="how-it-works__content container">
        <h2 className="brown-light fw-bold mb-5">Como fazemos</h2>
        <p>
          Com uma equipe de {""}
          <strong className="brown-dark">
          especialistas
          </strong>
          {""} em constante desenvolvimento e {""}
          <strong className="brown-dark">
          atualizações
          </strong>
          , atuando com individualidade, confiança, comprometimento e seriedade
        </p>
        <div className="how-it-works__content__cards mb-3">
          {cardsData.map((card) => (
            <div className="cards">
              <img src={card.image} alt={""} />
              <span className="mt-5 fs-5">{card.text}</span>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center flex-column align-items-center">
          <h2 className="brown-dark mb-4 mt-4 fs-4 text-center ">
            Com o <strong> principal objetivo</strong> de <br />{" "}
            <strong> trazer segurança jurídica para todas suas ações!</strong>
          </h2>
          <p>
            Além de relatórios mensais individuais e comparativos, atendimentos virtuais e presenciais.
          </p>
        </div>
      </div>
    </div>
  );
};
