import smbolo from "../../assets/smbolo.svg";
import carrossel1 from "../../assets/carrossel1.png";
import carrossel2 from "../../assets/carrossel2.png";
import carrossel3 from "../../assets/carrossel3.png";
import carrossel4 from "../../assets/carrossel4.png";

import Carousel from 'react-bootstrap/Carousel';

import "./styles.css";

export const About = () => {
  return (
    <div className="about-section">
      <div className="container">
        <div className="about__logo">
          <img src={smbolo} className="about__logo"/>
        </div>
        <div className="about-section__content">
          <div className="about-section__content__left">          
            <h2 className="fw-bold brown-light fs-1">O que fazemos</h2>
            <p className="fw-bold brown-dark fs-5">
              Análise preventiva e assessoria jurídica, trazendo segurança e agilidade nas demandas rotineiras.
            </p>
            <p>
              Tendo dúvidas de qual a melhor ação a ser executada ou necessita de uma consultoria?
            </p>
            <p>
              O <strong className="brown-dark">MHADV</strong> faz essa parte para que você tenha mais tranquilidade e nenhuma dúvida, destinando seu tempo para soluções!
            </p>
          </div>
          <div className="about-section__content__right">
            <h2 className="fw-bold brown-red fs-1">Por quê?</h2>
            <p>
            O <strong className="brown-dark">MHADV</strong> é especializado na prestação de serviços a órgãos públicos, consultoria jurídica para partidos políticos e seus candidatos.
            </p>
            <p>
              Queremos que
              <strong className="brown-dark">
                {" "}
                órgãos públicos atuem com efetividade
              </strong>,{" "}
              <strong className="brown-dark">
                {" "}
                as empresas licitantes executem seus serviços, partidos políticos organizem suas campanhas eleitorais
              </strong>
              , enquanto nosso {" "}
              <strong className="brown-dark"> time de especialistas</strong>
              {" "} solucionam suas demandas!
            </p>
          </div>
        </div>
      </div>
      <div className="container photographer-container">
        <Carousel fade>
          <Carousel.Item>
            <img src={carrossel1} className="photographer-img" />
          </Carousel.Item>
          <Carousel.Item>
            <img src={carrossel2} className="photographer-img" />
          </Carousel.Item>
          <Carousel.Item>
            <img src={carrossel3} className="photographer-img" />
          </Carousel.Item>
          <Carousel.Item>
            <img src={carrossel4} className="photographer-img" />
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};
